<template>
  <div class="wall-newspaper">
    <Section limiter>
      <div class="wall-newspaper__title" id="page-header">
        Новый год в Томске и Томской области - афиша мероприятий
        <span>Внимание! Рекомендуем уточнить актуальную информацию по указанным телефонам.</span>
      </div>

      <div class="wall-newspaper__nav">
        <div class="region-filter">
          <select v-model="selectedRegion">
            <option value="" selected>&nbsp;- укажите ваш населенный пункт -&nbsp;</option>
            <option v-for="(region, index) in regions" :key="index" :value="region">
              {{ region }}
            </option>
          </select>
        </div>
<!--        <a-->
<!--          :href="`#region-${index}`"-->
<!--          class="wall-newspaper__nav-item"-->
<!--          :class="{ '_active': index === currentIndex}"-->
<!--          v-for="(region, index) in regions"-->
<!--          :key="index"-->
<!--        >-->
<!--          {{ region }}-->
<!--        </a>-->
      </div>

      <div class="wall-newspaper__content">
          <div
            class="event-region"
            v-for="(region, regionIndex) in filteredRegions"
            :key="regionIndex"
          >
            <div class="event-region__anchor" :id="`region-${regionIndex}`"></div>
            <div class="event-region__name">{{ region }}</div>
            <div class="event-region__list">
              <div
                class="event-region__list-item"
                v-for="(event, index) in getEventsByRegion(region)"
                :key="index"
              >
                <div class="event-card" v-if="event.text">
                  <div class="event-card__title">{{ event.text }}</div>
                  <div class="event-card__date" v-if="event.date">
                    <span>Дата мероприятия:</span> {{ event.date }}
                  </div>
                  <div class="event-card__date" v-if="event.time">
                    <span>Время проведения:</span> {{ event.time }}
                  </div>
                  <div class="event-card__address" v-if="event.address">
                    <span>Адрес:</span> {{ event.address }}
                  </div>
                  <div class="event-card__contacts" v-if="event.phone">
                    <span>Телефон:</span> {{ event.phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <transition name="fade">
        <a href="#page-header" class="wall-newspaper__button-to-top" v-show="isButtonToTopVisible">
          <Icon viewport="0 0 20 20" fill="#fff" xlink="arrow-navigation" />
        </a>
      </transition>
    </Section>
  </div>
</template>

<script>
import events from '../assets/json/eventsNY2025.json';

export default {
  data: () => ({
    events,
    selectedRegion: '',
    isButtonToTopVisible: false,
  }),

  methods: {
    getEventsByRegion(region) {
      const result = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const event of this.events) {
        const place = event.location + (event.locationType !== 'город' ? ` (${event.locationType})` : '');

        if (place === region) {
          result.push(event);
        }
      }

      return result;
    },
    checkOnButtonToTopVisible() {
      this.isButtonToTopVisible = document.scrollingElement.scrollTop > (window.innerHeight / 4);
    },
  },

  computed: {
    regions() {
      const result = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const event of this.events) {
        const place = event.location + (event.locationType !== 'город' ? ` (${event.locationType})` : '');

        if (!result.includes(place)) {
          result.push(place);
        }
      }

      return result;
    },
    filteredRegions() {
      return this.selectedRegion ? [this.selectedRegion] : this.regions;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.checkOnButtonToTopVisible);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkOnButtonToTopVisible);
  },
};
</script>

<style lang="scss" scoped>
  $greenColor: #0eb519;
  $darkGreenColor: #00a367;

  .wall-newspaper {
    padding: 50px 0;

    &__title {
      font-size: 60px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: normal;
    }

    &__nav {
      display: flex;
      flex-wrap: wrap;

      .region-filter {
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 2rem;
        border-bottom: solid 1px #a3a3a3;

        select {
          height: 36px;
          border-radius: 5px;
          border: 1px solid #cdcdcd;
          font-size: 16px;
          background-color: #ffffff;
          width: 100%;
          color: #727272;

          &:focus-visible {
            outline: none;
          }
        }
      }

      &-item {
        margin: 5px;
        padding: 7px 16px;
        border: 2px solid $greenColor;
        border-radius: 5px;
        opacity: .8;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        color: $greenColor;
        cursor: pointer;

        &._active, &:hover {
          background-color: $greenColor;
          color: white;
        }
      }
    }

    &__content {
      .event-region {
        position: relative;

        &__anchor {
          position: absolute;
          top: -100px;
        }

        &__name {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 1.25rem;
          margin-top: 2rem;
          color: $darkGreenColor;
        }

        &__list {
          &-item {
            .event-card {
              margin-bottom: 1.5rem;
              padding: 1.25rem;
              border-radius: 5px;
              box-shadow: 0 1px 2px 0 rgba(0,0,0,.18),
                0 1px 2px 0 rgba(0,0,0,.04),
                0 2px 6px 0 rgba(0,0,0,.04);

              &:hover {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
              }

              & > div {
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: normal;
                color: rgba(0,0,0,.75);
                margin-bottom: 0.5rem;

                span {
                  color: rgba(0,0,0,.5);
                  font-weight: bold;
                }
              }

              &__title {
                font-size: 22px !important;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    &__button-to-top {
      $buttonToTopSize: 50px;
      position: fixed;
      right: 2rem;
      bottom: 2rem;
      border-radius: 50%;
      height: $buttonToTopSize;
      width: $buttonToTopSize;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $darkGreenColor;

      svg {
        margin-top: -6px;
      }
    }
  }

  @media (max-width: 1110px) {
    .wall-newspaper {
      padding: 36px 0;

      &__title {
        font-size: 24px;
      }
    }
  }
</style>
