<template>
  <div class="home-view">
    <div class="home-view__wrapper">
<!--      <Section>-->
<!--        <NewYearView />-->
<!--      </Section>-->
      <Section>
        <HeroSlider>
          <template v-slot:slide0>
            <p class="hero-slider__suptitle">Туристический гид: экскурсии, маршруты,
              достопримечательности, отели, базы отдыха, рестораны, афиша Томска</p>
            <h1 class="hero-slider__title h1">Совершите путешествие в сердце Сибири</h1>
            <div class="hero-slider__description">
              Исследуйте <a class="hero-slider__link" href="/municipality/region">Томскую
              область</a> — Сердце Сибири! Если вы начинающий путешественник — ищите с помощью
              туристического гида самые <a class="hero-slider__link" href="/places">интересные
              места</a>, <a class="hero-slider__link" href="/events">события</a> и <a
                class="hero-slider__link" href="/trips">готовые маршруты</a>. Если предпочитаете
              идти
              своей дорогой – создайте на карте уникальный путь и станьте первооткрывателем. Если вы
              житель Томской области – делитесь любимыми местами и
              <span class="hero-slider__link" v-on:click="$openPopupAddFact">интересными
                историями</span> о них.
            </div>
          </template>
          <template v-slot:slide1>
            <p class="hero-slider__suptitle">Причина приехать</p>
            <h1 class="hero-slider__title h1">Музей деревянного зодчества под открытым небом</h1>
            <div class="hero-slider__description">Томск входит в список «исторических поселений»
              России, состоящий всего из 41 населенного пункта. В Томске 18 памятников деревянного
              зодчества федерального значения, 98 – регионального, более 700 домов находятся под
              охраной для сохранения уникального архитектурного ландшафта.
            </div>
          </template>
          <template v-slot:slide2>
            <p class="hero-slider__suptitle">Маршрут</p>
            <h1 class="hero-slider__title h1">Памятник природы <br/>«Синий утес»</h1>
            <div class="hero-slider__description">Представляет собой крутой обрыв к реке Томь
              протяженностью до трех километров. Свое название получил благодаря глинистому сланцу,
              который при отдалении смотрится как голубовато-серый.
            </div>
          </template>
          <template v-slot:slide3>
            <p class="hero-slider__suptitle">Интересный факт</p>
            <h1 class="hero-slider__title h1">Первый университет Сибири</h1>
            <div class="hero-slider__description">В 1888 году в Томске был открыт Императорский
              Томский Университет, сейчас - Томский государственный университет.
            </div>
          </template>
        </HeroSlider>
      </Section>
      <Section>
        <Contents :cityId="$city.id"/>
      </Section>
      <Section limiter>
        <Factoid :facts="facts"/>
      </Section>
      <Section :limiter="$viewport.desktop">
        <FormationPortal :class="$viewport.desktop ? 'formation-portal-margin-120'
                         : 'formation-portal-margin-80'"
                         description="Знаете интересный факт или классные объекты, которых пока
                         нет на карте? Давайте развивать проект вместе!"
                         title="Участвуй в формировании портала">
          <Button color="transparent"
                  :full="$viewport.mobile"
                  v-on:click.native="$openPopupAddObject">Добавить объект
          </Button>
          <Button color="transparent"
                  :full="$viewport.mobile"
                  v-on:click.native="$openPopupAddFact">
            {{`Добавить ${$viewport.desktop ? 'интересный ' : ''}факт`}}
          </Button>
        </FormationPortal>
      </Section>
      <Section limiter>
        <BannerInteractiveCard/>
      </Section>
      <Section>
        <ReasonsVisitArea class="home-view__reasons-visit-area"/>
      </Section>
      <Divider/>
      <Section>
        <InterestingFacts/>
      </Section>
      <Section :limiter="$viewport.desktop">
        <FormationPortal class="formation-portal-margin-120"
                         description="Регистрируйся и добавляй информацию
                         о своей организации и услугах"
                         title="Для туристического бизнеса">
          <a class="button button__transparent"
             href="https://account.tomsk.life/account/restxq/organization-add">
            Добавить организацию</a>
        </FormationPortal>
      </Section>
      <Section>
        <PopularRoutes :cityId="$city.id" :isHome="true"/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeView',
    components: {
      InterestingFacts: () => import('../components/InterestingFacts'),
      ReasonsVisitArea: () => import('../components/ReasonsVisitArea'),
      PopularRoutes: () => import('../components/PopularRoutes'),
      HeroSlider: () => import('../components/HeroSlider'),
      BannerInteractiveCard: () => import('../components/BannerInteractiveCard'),
      Factoid: () => import('../components/Factoid'),
      // NewYearView: () => import('../views/NewYearView'),
    },
    metaInfo() {
      return {
        title: 'Туристический гид по Томской области',
        meta: [
          {
            property: 'vk:image',
            content: 'https://i.picsum.photos/id/823/700/400.jpg',
          },
          {
            property: 'og:site_name',
            content: 'Туристический гид по Томской области',
          },
          {
            property: 'og:title',
            content: 'Исследуйте Томскую область — Сердце Сибири!',
          },
          {
            property: 'og:description',
            content: 'Сервис для гостей и жителей региона, в котором собрана вся необходимая '
              + 'информация о событиях, экскурсиях, туристических маршрутах и '
              + 'достопримечательностях региона.',
          },
          {
            property: 'og:image',
            content: `${this.$host}/img/gerb.jpg`,
          },
          {
            property: 'og:url',
            content: `${this.$host}`,
          },
          {
            property: 'og:type',
            content: 'website',
          },
        ],
      };
    },
    data() {
      return {
        facts: [{
          title: 'Узнайте про <a href="/municipality/region">Томскую область</a>',
          description: 'Приезжайте в Томскую область и откройте для себя Сибирь.',
        }, {
          title: 'Ищите <a href="/trips">маршруты</a> и <a href="/excursions">экскурсии</a>',
          description: 'Маршруты, туры и экскурсии на любой вкус, для любого возраста и в любое время года.',
        }, {
          title: '<a href="/account?params=add">Добавляйте</a> свои объекты на карту',
          description: 'Создайте собственный маршрут и поделитесь опытом с теми, кому только предстоит открыть для себя Томскую область.',
        }],
        isNYModalShow: true,
      };
    },
    created() {
      this.$store.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
      });
    },
  };
</script>
